import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  Typography,
} from "@suraasa/placebo-ui"
// import { useMutation, useQueryClient } from "@tanstack/react-query"
import api from "api"
import { OTPResponse } from "api/resources/users/types"
import clsx from "clsx"
import AsyncBuilder from "components/shared/AsyncBuilder"
import ResendOTPButton from "components/shared/ResendOTPButton"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import OTPInput from "react-otp-input"
import { mapErrors } from "utils/helpers"
import { useGRecaptcha } from "utils/hooks/useGRecaptcha"
import toast from "utils/toast"

const PhoneNumberVerificationDialog = ({
  open,
  onClose,
  onSuccess,
  userPhoneNumber,
}: {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  userPhoneNumber: string | null
}) => {
  const [otpResponse, setOtpResponse] = useState<OTPResponse | null>(null)

  const { submitWithCaptcha } = useGRecaptcha({
    action: "auth",
    checkboxContainer: "#phone-number-checkbox-captcha",
  })

  const {
    handleSubmit,
    control,
    reset,
    setError,
    getValues,
    formState: { errors },
  } = useForm<{
    otp: string
  }>()

  useEffect(() => {
    if (open) {
      if (!otpResponse) {
        onSubmitToGetOTP()
      }
    }

    if (!open) {
      setOtpResponse(null)
      reset()
    }
  }, [open])

  const generateOTP = async ({
    number,
    captcha,
  }: {
    number: string
    captcha: any
  }) => {
    const res = await api.users.addPhoneNumber({
      data: { phoneNumber: number, channel: "both", captcha },
    })
    if (res.isSuccessful) {
      toast.success("OTP has been sent to your whatsapp/SMS.")
      setOtpResponse(res.data)
    } else {
      const phoneNumberError = res.errors.fieldErrors?.phoneNumber

      const message = res.errors.message
      if (
        message ===
        "An account has already been created with this phone number. Please use a different one"
      ) {
        mapErrors(setError, res.errors)
        onClose()
        return
      }

      if (phoneNumberError) {
        toast.error(phoneNumberError)
        onClose()
      } else {
        mapErrors(setError, res.errors)
      }
    }
  }

  const resendOTP = async ({ captcha }: { captcha: any }) => {
    const res = await api.users.sendOTP({
      data: {
        token: otpResponse?.token,
        captcha,
      },
    })
    if (res.isSuccessful) {
      toast.info("We've sent you a new OTP")
      setOtpResponse(res.data)
    } else {
      mapErrors(setError, res.errors)
    }
  }

  const handleResendOTP = async () => {
    await submitWithCaptcha(async captcha => {
      await resendOTP({ captcha })
    })()
  }

  const verifyOTP = async (otp: string) => {
    const res = await api.users.verifyOtp({
      data: {
        token: otpResponse?.token,
        otp,
      },
    })

    if (res.isSuccessful) {
      try {
        await api.users.update({
          data: {
            phoneNumber: userPhoneNumber!,
            smsOtpToken: res.data.token,
          },
        })
      } catch (e) {
        console.log("Error linking number to user", e)
      }

      toast.success("Phone number verified successfully.")
      onSuccess()
      onClose()
    } else {
      mapErrors(setError, res.errors, [["otp"]])
    }
  }

  const onSubmitToGetOTP = async () => {
    await submitWithCaptcha(async captcha => {
      if (!captcha) {
        toast.error("Please verify the captcha")
        return
      }
      await generateOTP({
        number: userPhoneNumber!,
        captcha,
      })
    })()
  }

  const onSubmitToVerifyOTP = handleSubmit(async data => {
    const otp = getValues("otp")

    let hasError = false

    if (!otp || otp.length < 6) {
      hasError = true
    }
    if (hasError) return

    await verifyOTP(otp)
  })

  return (
    <Dialog open={open} onRequestClose={onClose}>
      <DialogTitle>
        <Typography variant="strong">Verify your Phone Number</Typography>
      </DialogTitle>
      <DialogContent>
        <div>
          {!otpResponse && (
            <div className="flex items-center justify-center">
              <CircularProgress />
            </div>
          )}
          {otpResponse && (
            <>
              <Typography className="mb-2">
                OTP sent to <b>{userPhoneNumber}</b> on <b>SMS & WhatsApp</b>
              </Typography>

              <Controller
                control={control}
                name="otp"
                render={({ field }) => (
                  <OTPInput
                    numInputs={6}
                    {...field}
                    containerStyle="justify-start gap-1"
                    inputStyle={clsx(
                      "h-[50px] !w-[41px] rounded-md border-2 border-solid border-onSurface-500 focus:ring-2 focus:ring-interactive-500 focus:ring-offset-1",
                      {
                        "!border-critical-500 !text-critical-500": Boolean(
                          errors.otp
                        ),
                      }
                    )}
                    inputType="number"
                    renderInput={props => <input {...props} />}
                    shouldAutoFocus
                  />
                )}
                rules={{
                  required: { value: true, message: "Required" },
                  minLength: { value: 6, message: "Please enter the full OTP" },
                }}
              />
              {Boolean(errors.otp) && (
                <Typography
                  className="mt-1 !text-critical-500"
                  variant="smallBody"
                >
                  {errors.otp?.message}
                </Typography>
              )}

              <div className="mt-2 flex flex-wrap items-center">
                <Typography className="me-1 shrink-0">
                  Didn’t receive OTP?
                </Typography>
                <ResendOTPButton
                  resendAt={otpResponse.resendAt}
                  text="Send Again"
                  onClick={() => handleResendOTP()}
                />
              </div>
            </>
          )}
          <div id="phone-number-checkbox-captcha" className="mt-2" />
        </div>
      </DialogContent>
      <DialogFooter>
        <div className="flex justify-end">
          <AsyncBuilder
            handler={onSubmitToVerifyOTP}
            render={({ loading, onClick }) => (
              <Button
                type="button"
                onClick={onClick}
                loading={loading}
                size="sm"
                disabled={!otpResponse}
              >
                Verify Number
              </Button>
            )}
          />
        </div>
      </DialogFooter>
    </Dialog>
  )
}

export default PhoneNumberVerificationDialog
