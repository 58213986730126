import { ValueOf } from "utils/helpers"

export const STEPS = {
  JobOverview: 1,
  PersonalDetails: 2,
  MarketingData: 3,
  WorkExperience: 4,
  AcademicDetails: 5,
  UploadResume: 6,
  Congratulations: 7,
} as const

export const getStepName = (step: ValueOf<typeof STEPS>): string => {
  switch (step) {
    case STEPS.JobOverview:
      return "Job Overview"
    case STEPS.PersonalDetails:
      return "Personal Details"
    case STEPS.MarketingData:
      return "Marketing Data"
    case STEPS.WorkExperience:
      return "Work Experience"
    case STEPS.AcademicDetails:
      return "Academic Details"
    case STEPS.UploadResume:
      return "Upload Resume"
    case STEPS.Congratulations:
      return "Congratulations"
  }
}

export const TRANSITION_DURATION = 650

export const GRADES = [
  { label: "Nursery", value: "nursery" },
  { label: "Elementary", value: "elementary" },
  { label: "Primary", value: "primary" },
  { label: "Secondary", value: "secondary" },
  {
    label: "Senior Secondary / Junior College",
    value: "senior-secondary-junior-college",
  },
]

export const MARKETING_QUESTIONS = {
  totalYearsOfWorkExperience: "Total years of work experience",
  lookingForJobs: "Are you looking for jobs?",
  currentlyTeaching: "Are you currently teaching?",
  subjects: {
    lookingForJobs: "What is your primary/main subject?",
    areYouCurrentlyTeaching: "What is your primary/main subject?",
    notLookingForJobsAndNotCurrentlyTeaching:
      "What is your primary/main subject?",
  },
  grades: "What grades can you teach?",
  preferredWorkLocations: "Where do you want to teach?",
} as const
