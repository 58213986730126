import { NotificationList, useNotifications } from "@suraasa/notifications"
// import { JobsInUsaCard } from "@suraasa/utils"
import api from "api"
import axios from "api/axios"
import { Enrollment } from "api/resources/enrollments/types"
import { AcceptedJobOverview, StepsOverview } from "api/resources/jobs/types"
import { usePostHog } from "posthog-js/react"
import React, { createContext, useEffect, useState } from "react"
import { Outlet, useLocation } from "react-router-dom"
import { getAuthInfo, saveAuthInfo } from "utils/auth"
import { isLocalhost, NotificationAction } from "utils/constants"
import { buildUserName } from "utils/helpers"

export type Context = {
  overview: {
    set: React.Dispatch<React.SetStateAction<AcceptedJobOverview>>
  } & AcceptedJobOverview
  stepsOverview: StepsOverview & {
    loading: boolean
    _extra?: { autoRedirect: boolean }
  }
  setStepsOverview: React.Dispatch<
    React.SetStateAction<StepsOverview & { loading: boolean }>
  >
  notifications: NotificationList<NotificationAction>
  enrollments: Enrollment[]
}

const defaultValues = {
  overview: {
    jobsLockedTill: "",
    set: () => {},
  },
  enrollments: [],

  stepsOverview: {
    marketingFormDataExists: false,
    profileDataExists: false,
    resumeExists: false,
    marketingSignupComplete: false,
    qualificationDataExists: false,
    loading: true,
    _extra: {
      autoRedirect: true,
    },
  },
  setStepsOverview: () => {},
  notifications: { data: null, unreadCount: 0, totalCount: 0 },
}

export const GlobalContext = createContext<Context>(defaultValues)

const enrollmentsForUSAJobs = [
  {
    type: "course",
    slug: "boosting-employability",
  },
  {
    type: "qualification",
    slug: "professional-graduate-certificate-in-teaching-learning",
  },
]

const GlobalState = () => {
  const [overview, setOverview] = useState<AcceptedJobOverview>({
    jobsLockedTill: "",
  })

  const [enrollments, setEnrollments] = useState<Enrollment[]>([])

  const [stepsOverview, setStepsOverview] = useState<Context["stepsOverview"]>(
    defaultValues.stepsOverview
  )

  const [notifications] = useNotifications({ axiosInstance: axios })

  useEffect(() => {
    const fetchData = async () => {
      const res = await api.jobs.stepsOverview()
      const profileRes = await api.profile.retrieve()

      if (profileRes.isSuccessful && res.isSuccessful) {
        setStepsOverview(prevState => ({
          ...prevState,
          ...res.data,
          resumeExists: !!profileRes.data.resume,
          loading: false,
        }))
      }

      if (profileRes.isSuccessful) {
        const auth = getAuthInfo()
        if (auth)
          saveAuthInfo({
            ...auth,
            user: { ...auth.user, photo: profileRes.data.picture || "" },
          })
      }
    }

    if (getAuthInfo()) fetchData()
    else setStepsOverview(prevState => ({ ...prevState, loading: false }))
  }, [setStepsOverview])

  useEffect(() => {
    const listEnrollments = async () => {
      const res = await api.enrollments.list()
      if (res.isSuccessful) {
        setEnrollments(res.data)
      }
    }

    if (getAuthInfo()) {
      listEnrollments()
    }
  }, [])

  const posthog = usePostHog()
  const location = useLocation()

  useEffect(() => {
    posthog.capture("$pageview")
    // return () => {
    // posthog.capture("$pageleave")
    // console.log(`> Capturing page leave: ${location.pathname}`)
    // }
  }, [location])

  useEffect(() => {
    const authInfo = getAuthInfo()
    if (authInfo) {
      posthog?.identify(authInfo.user.uuid, {
        email: authInfo.user.email,
        name: buildUserName(authInfo.user),
      })
    }
  }, [])

  useEffect(() => {
    if (isLocalhost) {
      posthog.debug()
    }
  }, [])

  return (
    <GlobalContext.Provider
      value={{
        overview: { ...overview, set: setOverview },
        stepsOverview,
        setStepsOverview,
        enrollments,

        notifications,
      }}
    >
      <Outlet />
      {/* <CheckEnrollment items={enrollmentsForUSAJobs}>
        <JobsInUsaCard />
      </CheckEnrollment> */}
    </GlobalContext.Provider>
  )
}

export default GlobalState
