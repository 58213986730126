import { CssBaseline, theme } from "@suraasa/placebo-ui"
import CheckAccess from "components/auth/CheckAccess"
import ErrorPage from "components/ErrorPage"
import JobLocked from "components/jobs/JobLocked"
import GlobalState from "GlobalState"
import { PostHogProvider } from "posthog-js/react"
import { useEffect } from "react"
import { ThemeProvider } from "react-jss"
import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom"
import { getAuthInfo } from "utils/auth"
import { isLocalhost } from "utils/constants"
import { GA } from "utils/googleAnalytics"
import { routes } from "utils/routes"
import ToastProvider from "utils/toast/ToastProvider"
import { trackingService } from "utils/trackingService"
import { webengage } from "utils/webengage"
import AttemptAssessment from "views/assessments/Attempt"
import Logout from "views/auth/Logout"
import SSO from "views/auth/SSO"
import Explore from "views/Explore"
import Home from "views/Home"
import CompleteProfilePage from "views/Home/CompleteProfilePage"
import AttemptInterview from "views/interviews/Attempt"
import WaitingForHost from "views/interviews/WaitingForHost"
import Timeline from "views/jobs/Timeline"
import ViewOffer from "views/jobs/ViewOffer"
import SchoolProfilePage from "views/schoolProfile"
import SentryTest from "views/SentryTest"

const WEBENGAGE_KEY = import.meta.env.VITE_WEBENGAGE_LICENSE_CODE

const IS_LOCAL = import.meta.env.MODE === "development"

const PrivateRoute = () => {
  const auth = getAuthInfo()
  return auth ? (
    <Outlet />
  ) : (
    <Navigate
      to={{
        pathname: routes.sso,
        search: `?next=${window.location.pathname}`,
      }}
      replace
    />
  )
}

function App() {
  useEffect(() => {
    GA.init()

    const authInfo = getAuthInfo()
    if (authInfo) {
      try {
        trackingService.initUser(authInfo)
      } catch (err) {
        console.error(err)
      }
    }
    if (WEBENGAGE_KEY) {
      webengage?.init(WEBENGAGE_KEY)
      webengage?.options("safariWebPush", true)
      if (IS_LOCAL) {
        webengage?.debug(true)
      }
    }

    if (WEBENGAGE_KEY && !webengage) {
      console.log("WebEngage was not available")
    }
  }, [])

  // if (import.meta.env.VITE_MAINTENANCE === "true") {
  //   return (
  //     <ThemeProvider theme={theme}>
  //       <CssBaseline />
  //       <Maintenance firebaseDb={}/>
  //     </ThemeProvider>
  //   )
  // }

  return (
    <PostHogProvider
      apiKey={import.meta.env.VITE_PUBLIC_POSTHOG_KEY}
      options={{
        api_host: isLocalhost
          ? "https://us.i.posthog.com"
          : `${window.location.origin}/ingest`,
        autocapture: {
          dom_event_allowlist: ["click"], // DOM events from this list ['click', 'change', 'submit']
          element_allowlist: ["button", "a"], // DOM elements from this list ['a', 'button', 'form', 'input', 'select', 'textarea', 'label']
          css_selector_allowlist: ["[data-ph-autocapture]"], // List of CSS selectors
        },
        person_profiles: "identified_only",
        capture_pageview: false,
        capture_pageleave: false,
      }}
    >
      <Router>
        <ThemeProvider theme={theme}>
          <ToastProvider domRoot={document.body}>
            <CssBaseline />
            <Routes>
              <Route element={<SentryTest />} path={routes.error} />
              <Route element={<SSO />} path={routes.sso} />
              <Route element={<Logout />} path={routes.logout} />
              <Route element={<GlobalState />} path="/">
                {/* <Route
                element={<Navigate to={routes.home} />}
                path={routes.waitingList}
              /> */}

                <Route element={<PrivateRoute />} path="/">
                  <Route
                    element={<CompleteProfilePage />}
                    path={routes.completeProfile}
                  />
                </Route>

                <Route element={<Explore />} path={routes.explore} />
                <Route
                  element={<SchoolProfilePage />}
                  path={routes.schoolProfile}
                />

                <Route element={<PrivateRoute />} path="/">
                  {/* School Profile */}

                  <Route element={<JobLocked />} path={routes.acceptedOffer} />
                  <Route element={<CheckAccess />} path="/">
                    {/* Home */}
                    <Route element={<Home />} path={routes.home} />

                    {/* Jobs */}
                    {/* <Route element={<JobDetails />} path={routes.jobDetails} /> */}

                    {/* Assessments */}
                    <Route
                      element={<AttemptAssessment />}
                      path={routes.attemptAssessment}
                    />

                    {/* Interview */}
                    <Route
                      element={<AttemptInterview />}
                      path={routes.attemptInterview}
                    />
                    <Route
                      element={<WaitingForHost />}
                      path={routes.waitingForHost}
                    />

                    {/* Teachers */}
                    <Route element={<Timeline />} path={routes.jobTimeline} />
                    <Route element={<ViewOffer />} path={routes.viewOffer} />

                    <Route
                      element={<ErrorPage message="Page not found" />}
                      path={routes.pageNotFound}
                    />
                  </Route>
                </Route>
              </Route>
            </Routes>
          </ToastProvider>
        </ThemeProvider>
      </Router>
    </PostHogProvider>
  )
}

export default App
