import { AuthData } from "api/resources/users/types"
import snakeCase from "lodash/snakeCase"
import posthog from "posthog-js"
import { GA } from "utils/googleAnalytics"
import { buildUserName } from "utils/helpers"
import { webengage } from "utils/webengage"

type Service = "WebEngage" | "GA" | "PostHog"

const allServices: Service[] = ["WebEngage", "GA", "PostHog"]

export const trackingService = {
  initUser: (
    authInfo: {
      user: Pick<AuthData["user"], "firstName" | "lastName" | "uuid" | "email">
    },
    services = allServices
  ) => {
    GA.setUserId(authInfo.user.uuid)

    posthog?.identify(authInfo.user.uuid, {
      email: authInfo.user.email,
      name: buildUserName(authInfo.user),
    })

    try {
      if (webengage && services.includes("WebEngage")) {
        webengage.user.login(authInfo.user.uuid)
        webengage.user.setAttribute("we_email", authInfo.user.email)
        webengage.user.setAttribute("we_first_name", authInfo.user.firstName)
        webengage.user.setAttribute(
          "we_last_name",
          authInfo.user.lastName || ""
        )
      }
    } catch (e) {
      console.warn("Error initialising user on WebEngage", e)
    }
  },
  trackEvent: (eventName: string, data?: any, services = allServices) => {
    try {
      if (webengage && services.includes("WebEngage")) {
        webengage.track(eventName, data)
      }
    } catch (e) {
      console.warn(`Error tracking event on WebEngage: ${eventName}`, data)
    }

    try {
      if (posthog && services.includes("PostHog")) {
        posthog.capture(eventName, data)
      }
    } catch (e) {
      console.warn(`Error tracking event on PostHog: ${eventName}`, data)
    }
  },
  setUserAttribute: (key: string, value: any, services = allServices) => {
    // console.log(`> Tracking user attribute: ${key}=${value}`)
    try {
      if (webengage && services.includes("WebEngage")) {
        webengage.user.setAttribute(key, value)
      }
    } catch (e) {
      console.warn(`Error tracking user attribute on WebEngage: ${key}`, value)
      console.warn(e)
    }
  },
}

/**
 *
 * @param params Prefer writing object keys in snake case. If you don't then they'll automatically be converted to snake case.
 */
export const trackElementOnPH = (params: Record<string, any>) => {
  const formattedParams: Record<string, any> = {
    "data-ph-autocapture": true,
  }
  Object.entries(params).forEach(([key, value]) => {
    formattedParams[`data-ph-capture-attribute-${snakeCase(key)}`] = value
  })

  return formattedParams
}
