import { TextField, Typography } from "@suraasa/placebo-ui"
import debounce from "lodash/debounce"
import { useCallback, useEffect } from "react"
import { useFormContext } from "react-hook-form"

import { MARKETING_QUESTIONS } from "../constants"

const name = "totalYearsOfWorkExperience"

const TotalYearsOfWorkExperience = ({
  handleClick,
}: {
  handleClick: () => void
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  const submit = (e: any) => {
    const value = e?.target?.value
    if (value) handleClick()
  }

  const debouncedOnChange = useCallback(
    debounce(e => submit(e), 2500),
    []
  )

  useEffect(() => {
    return () => {
      debouncedOnChange.cancel()
    }
  }, [debouncedOnChange])

  return (
    <div>
      <div className="grid md:grid-cols-2">
        <TextField
          label={MARKETING_QUESTIONS.totalYearsOfWorkExperience}
          className="col-span-1"
          error={Boolean(errors[name])}
          helperText={errors[name]?.message as string}
          placeholder="Ex. 3"
          type="number"
          min={0}
          endIcon={
            <Typography variant="smallBody" color="onSurface.500">
              Years
            </Typography>
          }
          fullWidth
          onKeyDown={e => {
            if (e.key === "Enter") {
              submit(e)
            }
          }}
          required
          {...register(name, {
            required: "Required",
            onBlur: submit,
            onChange: debouncedOnChange,
          })}
        />
      </div>
    </div>
  )
}

export default TotalYearsOfWorkExperience
