import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getSolisURL = getServiceURL(ServicePrefix.solis)

export const urls = validateUrls({
  user: { base: () => getSolisURL("/v1/users/") },
  auth: {
    refreshToken: () => getSolisURL(`/v1/auth/refresh-token/`),
    generatePlatformToken: () => getSolisURL("/v1/auth/user-tokens/"),
  },
  phoneNumber: {
    add: () => getSolisURL(`/v1/users/phone-numbers/unverified/`),
  },
  otp: {
    verify: () => getSolisURL(`/v1/users/otp/verify/`),
    send: () => getSolisURL(`/v1/users/otp/`),
  },
})
