import axios from "api/axios"
import { APIResponse, Config } from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import { AuthData, LearningUser, OTPResponse } from "./types"
import { urls } from "./urls"

export default {
  generatePlatformToken: async ({
    data,
  }: Config): Promise<APIResponse<AuthData>> => {
    try {
      const res = await axios.post(urls.auth.generatePlatformToken(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  verifyOtp: async ({
    data,
  }: Config): Promise<APIResponse<Pick<OTPResponse, "token">>> => {
    try {
      const res = await axios.post(urls.otp.verify(), data, {
        headers: {
          "device-id": "not-supported",
        },
      })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  sendOTP: async ({ data }: Config): Promise<APIResponse<OTPResponse>> => {
    try {
      const res = await axios.post(urls.otp.send(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  addPhoneNumber: async ({
    data,
  }: Config): Promise<APIResponse<OTPResponse>> => {
    try {
      const res = await axios.post(urls.phoneNumber.add(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  update: async ({ data }: Config): Promise<APIResponse<LearningUser>> => {
    try {
      const res = await axios.put(urls.user.base(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  retrieve: async (): Promise<APIResponse<LearningUser>> => {
    try {
      const res = await axios.get(urls.user.base())
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  // apiName: async (): Promise<APIResponse<ResponseType>> => {
  //   try {
  //     const res = await axios.get(urls.auth.apiURLName)
  //     return formatSuccessResponse(res)
  //   } catch (e) {
  //     return formatErrorResponse(e)
  //   }
  // },
}
